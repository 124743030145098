import { useLogoutMutation } from 'api/auth.api';
import { useAppDispatch } from 'configuration/redux/store';
import { logoutAction } from 'slices/auth-management/actions';
import { useAnalytics } from 'use-analytics';

interface UseLogoutHookInterface {
  handleLogout: () => Promise<void>;
}

export const useLogout = (): UseLogoutHookInterface => {
  const { track, reset } = useAnalytics();
  const [logout] = useLogoutMutation();
  const dispatch = useAppDispatch();
  const handleLogout = async () => {
    await logout(undefined);
    dispatch(logoutAction());
    await track('userLoggedOut');
    await reset();
  };
  return { handleLogout };
};
