import { EyeIcon, EyeOffIcon } from '@otto-finance/ui';
import clsx from 'clsx';
import { Typography } from 'common/otto-ui';
import { CloseIcon } from 'common/otto-ui/icons';
import {
  forwardRef,
  InputHTMLAttributes,
  ReactEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export enum TextFieldTypeEnum {
  COLOR = 'color',
  DATE = 'date',
  EMAIL = 'email',
  FILE = 'file',
  HIDDEN = 'hidden',
  IMAGE = 'image',
  MONTH = 'month',
  NUMBER = 'number',
  PASSWORD = 'password',
  SEARCH = 'search',
  TEL = 'tel',
  TEXT = 'text',
  URL = 'url',
  WEEK = 'week',
}

export enum TextFieldSizeEnum {
  LARGE = 'large',
  SMALL = 'small',
  SMALLEST = 'smallest',
  EXTRA_SMALL = 'extra_small',
}

export enum TextFieldStatusEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  DEFAULT = 'default',
}

export enum TextFieldVariation {
  DEFAULT = 'default',
  SHADOW = 'shadow',
}

export interface TextFieldInterface extends Omit<InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>, 'size'> {
  /** Filled background of input field */
  filled?: boolean;
  /** Outlined border  of component */
  outline?: boolean;
  /** Label for component */
  label?: string;
  /** Click event listener function on reset icon */
  onReset?: () => void;
  /** Type of input field */
  type?: Lowercase<keyof typeof TextFieldTypeEnum>;
  /** Helper text bottom of component */
  helperText?: string | ReactNode;
  /** Status succes, error or default of input field */
  status?: Lowercase<keyof typeof TextFieldStatusEnum>;
  /** Size of the component */
  size?: Lowercase<keyof typeof TextFieldSizeEnum>;
  /** Left icon inside of component*/
  icon?: ReactNode;
  /** Component will have a full width of parent container */
  fullWidth?: boolean;
  /** Left side place inside component */
  leftSide?: ReactNode;
  /** enable or disable placeholder on focus with empty value, default false */
  placeholderOnFocus?: boolean;
  /** Support multiline input */
  multiline?: boolean;
  /** Right side place inside component */
  rightSide?: ReactNode;
  /** If true Eye icon should be displayed */
  showTextVisibilitySwitch?: boolean;
  /** CSS classes for Helper Text */
  helperTextClassName?: string;
  /** CSS classes for Inner Wrapper */
  innerWrapperClassName?: string;
  /** Determine if we should animated */
  animate?: boolean;
  ref?: React.Ref<HTMLInputElement>;
  /** Varations: Underline (default), box-shadow */
  variation?: TextFieldVariation;
}

/** Text field component of input with customized styles and additional properties */
export const TextField = forwardRef<HTMLInputElement, TextFieldInterface>(
  (
    {
      filled,
      outline,
      id,
      name,
      value,
      onFocus,
      onBlur,
      onChange,
      onKeyDown,
      onReset,
      type = TextFieldTypeEnum.TEXT,
      className,
      placeholder,
      label,
      multiline,
      disabled,
      helperText,
      status = TextFieldStatusEnum.DEFAULT,
      size = TextFieldSizeEnum.SMALL,
      icon,
      autoComplete,
      leftSide,
      rightSide,
      placeholderOnFocus,
      fullWidth,
      showTextVisibilitySwitch = false,
      helperTextClassName,
      animate = false,
      innerWrapperClassName,
      variation,
      ...props
    },
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [isInFocus, setIsInFocus] = useState<boolean>(false);
    const [shouldShowPassword, setShouldShowPassword] = useState<boolean>(false);

    const isDisabled = Boolean(props?.['aria-disabled']) || disabled;

    const classes = {
      wrapper: clsx({
        [className]: className,
        'w-full': fullWidth,
      }),
      innerWrapper: clsx('relative group flex flex-col justify-around box-border overflow-hidden bg-white dark:bg-transparent', {
        'rounded-lg bg-label focus-within:bg-offWhite dark:bg-body px-3': filled,
        'border-2 rounded-lg px-3': outline,
        'opacity-50 pointer-events-none select-none': isDisabled,
        'h-6': size === TextFieldSizeEnum.EXTRA_SMALL,
        'h-10.5': size === TextFieldSizeEnum.SMALLEST,
        'min-h-14': size === TextFieldSizeEnum.SMALL,
        'min-h-16': size === TextFieldSizeEnum.LARGE,
        'border-label': status === TextFieldStatusEnum.DEFAULT && outline,
        'hover:border-grey2 dark:hover:border-grey3': status === TextFieldStatusEnum.DEFAULT && outline && !isInFocus,
        'focus-within:border-grey3 dark:border-grey2 dark:focus-within:border-grey3':
          status === TextFieldStatusEnum.DEFAULT,
        'border-error focus-within:border-error': status === TextFieldStatusEnum.ERROR,
        'border-success focus-within:border-success': status === TextFieldStatusEnum.SUCCESS,
        'shadow-shadow1 py-0 px-2 rounded-md': variation === TextFieldVariation.SHADOW,
        [innerWrapperClassName]: innerWrapperClassName,
      }),
      input: clsx(
        'outline-none pl-2 border-none flex-auto text-base text-title bg-transparent dark:text-offWhite w-full',
        {
          'my-1.5': !isInFocus && !value,
          'mb-0.5': (isInFocus || value) && label,
          'ml-2': !!leftSide || (!!icon && type !== TextFieldTypeEnum.SEARCH),
          'ml-4': !!leftSide || (!!icon && type === TextFieldTypeEnum.SEARCH),
          'mr-9': rightSide,
          'focus:border-error': status === TextFieldStatusEnum.ERROR,
          'focus:border-success': status === TextFieldStatusEnum.SUCCESS,
          'animate-search-input-stretch': isInFocus && animate,
          'animate-search-input-shrink': !isInFocus && animate,
        },
      ),
      label: clsx('w-full text-xs tracking-wide font-medium mt-0.5', {
        'text-grey2 dark:text-offWhite': status === TextFieldStatusEnum.DEFAULT && !isInFocus,
        'text-primary dark:text-primary-light': status === TextFieldStatusEnum.DEFAULT && isInFocus,
        'text-error': status === TextFieldStatusEnum.ERROR,
        'text-success': status === TextFieldStatusEnum.SUCCESS,
      }),
      helperText: clsx('text-xs font-medium tracking-wide leading-none mt-2', {
        [helperTextClassName]: helperTextClassName,
        'px-6': outline,
        'text-grey2 dark:placeholder': status === TextFieldStatusEnum.DEFAULT,
        'text-error mb-1': status === TextFieldStatusEnum.ERROR,
        'text-success': status === TextFieldStatusEnum.SUCCESS,
      }),
      rightIcon: clsx('flex space-x-1 justify-end items-center', {
        'right-2': type !== TextFieldTypeEnum.SEARCH,
        'right-4': type === TextFieldTypeEnum.SEARCH,
      }),
      xIcon: clsx('flex-1 cursor-pointer'),
      passwordIcon: clsx('flex-1 cursor-pointer'),
      inputContainer: clsx('flex flex-row items-center justify-start'),
      outlineBorder: clsx('w-full absolute bottom-0 group-hover:border-b-2', {
        'border-b': !isInFocus,
        'border-b-2': isInFocus,
        'border-grey2 dark:group-hover:border-offWhite': status === TextFieldStatusEnum.DEFAULT && !isInFocus,
        'border-primary dark:border-primary-light': status === TextFieldStatusEnum.DEFAULT && isInFocus,
        'border-error': status === TextFieldStatusEnum.ERROR,
        'border-success': status === TextFieldStatusEnum.SUCCESS,
      }),
      labelText: clsx('font-medium mb-2'),
    };

    const textareaRef = useRef<HTMLTextAreaElement>();
    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';  
      }
    }, [value]);

    const handleIconResetClick = (event): void => {
      event.preventDefault();
      if (onReset) {
        onReset();
      }
      setIsInFocus(false);
    };
    const PasswordIcon = shouldShowPassword ? EyeOffIcon : EyeIcon;

    const handlePasswordVisibilityToggle: ReactEventHandler<SVGElement> = () => {
      setShouldShowPassword(!shouldShowPassword);
    };

    const labelVariant = useMemo(() => {
      switch (size) {
        case TextFieldSizeEnum.EXTRA_SMALL:
          return 'caption-2';
        case TextFieldSizeEnum.SMALLEST:
          return 'caption';
        case TextFieldSizeEnum.SMALL:
          return 'body-2';
        case TextFieldSizeEnum.LARGE:
          return 'subtitle';
        default:
          return 'body-1';
      }
    }, [size]);

    return (
      <label className={classes.wrapper} htmlFor={id}>
        {label && (
          <Typography className={classes.labelText} variant={labelVariant}>
            {label}
          </Typography>
        )}
        <div className={classes.innerWrapper}>
          <div className={classes.inputContainer}>
            {leftSide || icon}
            {!multiline && (
              <input
                id={id || `${name}-id`}
                name={name}
                value={value}
                onChange={onChange}
                onFocus={(e) => {
                  setIsInFocus(true);
                  if (onFocus) {
                    onFocus(e);
                  }
                }}
                onBlur={(e) => {
                  setIsInFocus(false);
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
                onKeyDown={onKeyDown}
                type={shouldShowPassword ? TextFieldTypeEnum.TEXT : type}
                placeholder={isInFocus && !placeholderOnFocus ? null : placeholder}
                disabled={isDisabled}
                autoComplete={autoComplete}
                className={classes.input}
                ref={ref}
                {...props}
              />
            )}
            {multiline && (
              <textarea
                id={id || `${name}-id`}
                ref={textareaRef}
                rows={1}
                name={name}
                value={value}
                onChange={onChange}
                onFocus={(e) => {
                  setIsInFocus(true);
                  if (onFocus) {
                    onFocus(e);
                  }
                }}
                onBlur={(e) => {
                  setIsInFocus(false);
                  if (onBlur) {
                    onBlur(e);
                  }
                }}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                disabled={isDisabled}
                autoComplete={autoComplete}
                className={classes.input}
              />
            )}
            <div className={classes.rightIcon}>
              {type !== 'number' && value && onReset && (
                <div onClick={handleIconResetClick} className={classes.xIcon}>
                  <CloseIcon width={18} height={18} />
                </div>
              )}
              <div className={classes.passwordIcon}>
                {showTextVisibilitySwitch && (
                  <PasswordIcon size={"20"} />
                )}
              </div>
              {rightSide}
            </div>
          </div>
          {!(outline || filled || variation) && <div className={classes.outlineBorder}></div>}
        </div>
        {type !== TextFieldTypeEnum.SEARCH && helperText && <div className={classes.helperText}>{helperText}</div>}
      </label>
    );
  },
);

TextField.displayName = 'TextField';
