import { gql } from 'graphql-request';

const commonReportFields = `
  id
  status
  customer {
    id
    firstName
    lastName
    avatar
    avatarImageIdentifier
  }
  appointmentId
  sections {
    index
    title
    text
  }
  dates {
    createdAt
    updatedAt
  }
`;

export const ListReportsDocument = gql`
  query ListReports($appointmentId: String, $userId: String) {
    listReports(appointmentId: $appointmentId, userId: $userId) {
      ${commonReportFields}
    }
  }
`;

export const GetReportDocument = gql`
  query GetReport($id: String!) {
    fetchReport(id: $id) {
      ${commonReportFields}
      html
    }
  }
`;

export const UpdateReportDocument = gql`
  mutation UpdateReport($html: String!, $reportId: String!) {
    updateReport(html: $html, reportId: $reportId) {
      id
    }
  }
`;

export const GenerateReportDocument = gql`
  mutation GenerateReportFromAppointment($appointmentId: String!, $type: ReportTypeEnum) {
    generateReportFromAppointment(appointmentId: $appointmentId, type: $type)
  }
`;

export const DeleteReportDocument = gql`
  mutation DeleteReport($id: String!) {
    deleteReport(id: $id)
  }
`;
