import { caching } from 'configuration/data';
import { CreateAcceptedTermDocument, GetGlossariesDocument, GetGlossaryDocument } from 'configuration/graphql/documents';
import { WealthProviderEnum } from 'enums';

import { API_TAGS, baseApi } from './base.api';

interface GlossaryResponse {
  glossary: {
    id: string;
    glossaryValues: { data: GlossaryInterface[] };
  };
}

interface GlossariesResponse {
  glossaries: {
    data: [
      {
        glossaryValues?: { data: GlossaryInterface[] };
      },
    ];
  };
}

export interface GlossaryInterface {
  glossaryId: string;
  value: unknown;
}

interface AcceptTermsArgs {
  userId: string;
  glossaryId: string;
  ipAddress: string;
  subject: string;
  description: string;
  provider?: WealthProviderEnum;
  browserInfo?: {
    userAgent: string;
  };
  additionalInfo?: {
    bankId: string;
    bankName: string;
  };
}

export const termsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    glossaries: builder.query<GlossaryInterface, { value: string; isHTML?: boolean; isMarkdown?: boolean; }>({
      query: ({ value }) => ({
        document: GetGlossariesDocument,
        variables: { value },
      }),
      transformResponse: (res: GlossariesResponse, _, { isHTML, isMarkdown }) => {
        if (res && res.glossaries.data.length) {
          const glossary = res.glossaries.data[0].glossaryValues.data[0];
          return {
            glossaryId: glossary.glossaryId,
            value: isHTML || isMarkdown ? glossary.value : JSON.parse(glossary.value as string),
          };
        }
        return null;
      },
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.GLOSSARIES],
    }),
    glossary: builder.query<GlossaryInterface, { id: string; isHTML?: boolean; isMarkdown?: boolean; }>({
      query: ({ id }) => ({
        document: GetGlossaryDocument,
        variables: { id },
      }),
      transformResponse: (res: GlossaryResponse, _, { isHTML, isMarkdown }) => {
        if (res && res.glossary.glossaryValues.data.length) {
          const glossary = res.glossary.glossaryValues.data[0];
          return {
            glossaryId: glossary.glossaryId,
            value: isHTML || isMarkdown ? glossary.value : JSON.parse(glossary.value as string),
          };
        }
      },
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.GLOSSARIES],
    }),
    createAcceptedTerm: builder.mutation<{ id: string }, AcceptTermsArgs>({
      query: (args) => ({
        variables: { acceptedTermData: args },
        document: CreateAcceptedTermDocument,
      }),
    }),
  }),
});

export const { useGlossariesQuery, useCreateAcceptedTermMutation, useGlossaryQuery, useLazyGlossaryQuery } = termsApi;
