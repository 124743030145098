
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import 'styles/tailwind.css';
import 'styles/theme.scss';
import 'styles/index.scss';
import "swiper/css";
import store from 'configuration/redux/store';
import { Provider } from 'react-redux';
import { AnalyticsProvider, AppConfigProvider, AppProvider, AppTheme, AppTourProvider } from 'providers';
import { ErrorBoundary as ErrorBoundaryFallback } from 'components/error-boundary';
import { ErrorBoundary } from '@sentry/react';
import { SnackbarProvider } from '@otto-finance/ui';
import { SafeHydrate } from "utils";
import { AppProps } from 'next/app';
import { PusherProvider } from 'common/roq-hooks/use-pusher';
const app = ({ Component, pageProps }: AppProps) => {
    return (<SafeHydrate>
      <ErrorBoundary fallback={<ErrorBoundaryFallback />} showDialog={process.env.NEXT_PUBLIC_SHOW_SENTRY_DIALOG === 'True'}>
        <Provider store={store}>
          <AppConfigProvider>
            <AnalyticsProvider>
              <AppTheme>
                <PusherProvider>
                  <AppTourProvider>
                    <AppProvider>
                      <SnackbarProvider placement='bottom'>
                        <Component {...pageProps}/>
                      </SnackbarProvider>
                    </AppProvider>
                  </AppTourProvider>
                </PusherProvider>
              </AppTheme>
            </AnalyticsProvider>
          </AppConfigProvider>
        </Provider>
      </ErrorBoundary>
    </SafeHydrate>);
};
const __Next_Translate__Page__193ab3af288__ = app;

    export default __appWithI18n(__Next_Translate__Page__193ab3af288__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  