
import { AvailabilityDocument, CalendarAvailabilityDocument, UpdateAvailabilityDocument } from 'configuration/graphql/documents/calendar.document';
import { API_TAGS, baseApi } from './base.api';
import { CoachAvailabilityDay } from 'common/interfaces';
import { caching } from 'configuration/data';
import { getDayOfWeekInt } from 'utils/dates';

export const calendarApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    availability: builder.query<{[dayNumber: number]: CoachAvailabilityDay;}, undefined>({
      query: (variables) => ({
        document: AvailabilityDocument,
        variables
      }),
      transformResponse: (response: { coachAvailability: { data; }; }) => {
        if (response?.coachAvailability?.data) {
          return response.coachAvailability.data.reduce((acc, c) => {
            const dayNumber = getDayOfWeekInt(c.dayOfWeek);
            acc[dayNumber] = {
              ...c,
              dayNumber,
              bufferTimeHours: Number(c.bufferTime.split(' ')[0])
            };
            return acc;
          }, {});
        }
        return {};
      },
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.COACH_AVAILABILITY],
    }),
    updateAvailability: builder.mutation<CoachAvailabilityDay[], CoachAvailabilityDay[]>({
      query: (variables) => ({
        document: UpdateAvailabilityDocument,
        variables: { data: variables }
      })
    }),
    calendarAvailability: builder.query<any, { start: string; end: string; calendarId: string; userId: string; duration: number; }>({
      query: (variables) => ({
        document: CalendarAvailabilityDocument,
        variables
      }),
      transformResponse: (response: { appointmentOwnersAvailability: { serviceProviderEnabledUsers }; }) => response.appointmentOwnersAvailability.serviceProviderEnabledUsers,
      keepUnusedDataFor: caching.day,
      providesTags: [API_TAGS.AVAILABILITY],
    }),
  })
});

export const {
  useCalendarAvailabilityQuery,
  useLazyCalendarAvailabilityQuery,
  useLazyAvailabilityQuery,
  useAvailabilityQuery,
  useUpdateAvailabilityMutation
} = calendarApi;



