import { addDays, differenceInYears, Duration, endOfMonth, format, formatDistanceStrict, startOfDay, startOfMonth, startOfYear, sub, subDays, subHours, subMonths, subWeeks, subYears } from "date-fns";
import { formatInTimeZone as fInTimezone } from 'date-fns-tz';
import { DATE_FORMAT, DATE_RANGE } from "enums";

export const humanReadFormat = (d: string): string => formatDistanceStrict(new Date(d), new Date(), { addSuffix: true });

export const formatDate = (d: string | Date, f: DATE_FORMAT = DATE_FORMAT.DEFAULT): string => format(new Date(d), f);

export const formatInTimeZone = (d: string | Date, tz: string, f: DATE_FORMAT = DATE_FORMAT.DEFAULT): string =>
  fInTimezone(d, tz, f);

export const getDatePeriodByDuration = (n: Duration, v: number, f = DATE_FORMAT.DEFAULT): { from: string; to: string; } => ({
  from: format(sub(new Date(), { [n.toString()]: v }), f),
  to: format(new Date(), f),
});

export const getDateRange = (d: DATE_RANGE, f: DATE_FORMAT = DATE_FORMAT.DEFAULT): [string, string] => {
  switch (d) {
    case DATE_RANGE.TODAY:
      return [format(startOfDay(new Date()), f), format(addDays(new Date(), 1), f)];
    case DATE_RANGE.LAST_DAY:
      return [format(subHours(new Date(), 24), f), format(new Date(), f)];
    case DATE_RANGE.THIS_MONTH:
      return [format(startOfMonth(new Date()), f), format(new Date(), f)];
    case DATE_RANGE.LAST_MONTH:
      return [format(startOfMonth(subMonths(new Date(), 1)), f), format(endOfMonth(subMonths(new Date(), 1)), f)];
    case DATE_RANGE.LAST_5_DAYS:
      return [format(subDays(new Date(), 5), f), format(new Date(), f)];
    case DATE_RANGE.LAST_30_DAYS:
      return [format(subMonths(new Date(), 1), f), format(new Date(), f)];
    case DATE_RANGE.LAST_6_MONTHS:
      return [format(subMonths(new Date(), 6), f), format(new Date(), f)];
    case DATE_RANGE.LAST_YEAR:
      return [format(subMonths(new Date(), 12), f), format(new Date(), f)];
    case DATE_RANGE.LAST_WEEK:
      return [format(subWeeks(new Date(), 1), f), format(new Date(), f)];
    case DATE_RANGE.LAST_3_MONTHS:
      return [format(subMonths(new Date(), 3), f), format(new Date(), f)];
    case DATE_RANGE.YEAR_TO_DATE:
      return [format(startOfYear(new Date()), f), format(new Date(), f)];
    case DATE_RANGE.ALL_TIME:
      return [format(subYears(new Date(), 5), f), format(new Date(), f)];
    default:
      return ['', ''];
  }
};

/**
 * Get array of dates between 2 dates.
 * 
 * @param start Start date
 * @param end End date
 * @returns 
 */
export const getDatePeriodArray = (start: Date, end: Date): Date[] => {
  const dates: Date[] = [];
  const currentDate = start;
  while (currentDate < end) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }
  dates.push(end);
  return dates;
};

export const ageFromDate = (date: string) => differenceInYears(new Date(), new Date(date));

export const getDayOfWeekInt = (day: string) => ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"].indexOf(day);
