import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'configuration/redux/base-queries';

export enum API_TAGS {
  ARTICLE = 'Article',
  ARTICLES = 'Articles',
  ARTICLE_TOPIC = 'ArticleTopic',
  ARTICLE_TOPICS = 'ArticleTopics',
  ACTIONS = 'Actions',
  ACTIONS_TASKS = 'ActionsTasks',
  ACTIONS_FILES = 'ActionsFiles',
  ACTIONS_GOALS = 'ActionsGoals',
  ACTIONS_USERS = 'ActionsUsers',
  ACTIONS_KANBAN = 'ActionsKanban',
  ACTIONS_TASK = 'ActionsTask',
  CONVERSATIONS = 'Conversations',
  FOLDERS = 'Folders',
  TARGETS = 'Targets',
  GLOSSARIES = 'Glossaries',
  USER = 'User',
  USER_PROFILE = 'UserProfile',
  USERS = 'Users',
  COACH = 'Coach',
  INCOME_EXPENSES = 'IncomeExpenses',
  NETWORTH = 'NetWorth',
  ACCOUNTS = 'Accounts',
  ACCOUNT_TYPES = 'AccountTypes',
  ACCOUNT_BALANCES = 'AccountBalances',
  CRYPTO_ACCOUNTS = 'CryptoAccounts',
  USER_COACH = 'UserCoach',
  CONNECTIONS = 'Connections',
  CUSTOMER_PROFILE = 'CustomerProfile',
  EVENT = 'Event',
  EVENTS = 'Events',
  HOLDINGS = 'Holdings',
  HOLDINGS_SEARCH = 'HoldingsSearch',
  TRANSACTIONS = 'Transactions',
  TRANSACTIONS_TABLE = 'TransactionsTable',
  SPENDING_ANALYSIS = 'SpendingAnalysis',
  SETTINGS = 'Settings',
  OTTOMATE = 'Ottomate',
  NOTIFICATIONS_UNREAD = 'NotificationsUnread',
  NOTIFICATIONS = 'Notifications',
  BUDGETS = 'Budgets',
  FINANCE_OVERVIEW = 'FinanceOverview',
  FINANCE_HOLDINGS = 'FinanceHoldings',
  FINANCE_NEWS = 'FinanceNews',
  FINANCE_CONNECTIONS = 'FinanceConnections',
  FINANCE_HISTORICAL_PRICES = 'FinanceHistoricalPrices',
  FINANCE_SECURITY_PROFILES = 'FinanceSecurityProfiles',
  FINANCE_SECURITY_DETAILS = 'FinanceSecurityDetails',
  HISTORICAL_BALANCES = 'HistoricalBalances',
  ROADMAP = 'Roadmap',
  SURVEYS = 'Surveys',
  SESSIONS = 'Sessions',
  SESSION = 'Session',
  SESSIONS_NEXT = 'SessionsNext',
  SESSION_NOTES = 'SessionNotes',
  FINANCE_PROFILE = 'FinanceProfile',
  INVESTMENT_IDEAS = 'InvestmentIdeas',
  INVESTMENT_WATCHLIST = 'InvestmentWatchlist',
  TIERS = 'Tiers',
  COMPANIES = 'Companies',
  PARTNERS = 'Partners',
  DOCUMENTS = 'Documents',
  REPORTS = 'Reports',
  FORMS = 'Forms',
  COACH_AVAILABILITY = 'CoachAvailability',
  AVAILABILITY = 'Availability'
}

export const accountsCacheTags = [
  API_TAGS.NETWORTH,
  API_TAGS.INCOME_EXPENSES,
  API_TAGS.FINANCE_OVERVIEW,
  API_TAGS.HOLDINGS,
  { type: API_TAGS.ACCOUNTS, id: 'ALL' },
  { type: API_TAGS.CRYPTO_ACCOUNTS, id: 'ALL' },
  { type: API_TAGS.TRANSACTIONS, id: 'ALL' },
  { type: API_TAGS.HISTORICAL_BALANCES, id: 'ALL' },
  { type: API_TAGS.SPENDING_ANALYSIS, id: 'ALL' }
];

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: Object.values(API_TAGS),
  endpoints: () => ({})
});
