
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { settingsApi } from 'api';
import { saveState } from 'configuration/redux/local-store';
import { StoreInterface } from 'configuration/redux/store';
import { overrideThemeUtil, saveThemeOverride } from './ui.util';
import { capitalizeFirstLetter } from 'utils';
import { logoutAction } from 'slices/auth-management/actions';

export const SETTINGS_VER = 13;

export enum UiThemeEnum {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum WealthTabsEnum {
  OVERVIEW = 'overview',
  CASHFLOW = 'cashflow',
  INVESTMENTS = 'investments',
  MANAGE = 'manage',
}
type TourPages = 'dashboard' | 'investments' | 'investmentsAnalysis';

type Page = {
  /** Is page full or empty */
  isFull: boolean;
  tour: {
    version: number;
    currentVersion: number;
  };
};

export type SiteSettings = {
  theme: Record<string, string>;
  title: string;
  faviconUrl: string;
  logoUrl: string;
  logoUrlContrast: string;
  logoWidth?: string;
  disableCoachingIntro: boolean;
  /**
   * Naming for coach.
   */
  naming: {
    service: string;
    persona: string;
  };
};

export interface UiInterface {
  dashboardView: 'homeView' | 'dataView';
  theme: UiThemeEnum;
  prompts: {
    /**
     * Whether to prompt the user to connect their financial accounts
     */
    connectAccounts: boolean,
  };
  /**
   * Whether the mobile drawer is open
   */
  mobileMenuOpen: boolean;
  wealthTab: WealthTabsEnum;
  /**
   * Version for new Features dialog
   */
  features: number;
  isDialogOpen: boolean;
  pageName: string;
  hasLoadedSettings: boolean;
  pages?: Record<TourPages, Page>;
  settings?: {
    version: number;
    isLoaded: boolean;
    site: SiteSettings;
    coachTitle?: string;
  };
  sidebar: {
    isWide: boolean;
    /**
    * Holds the ID of the current uncollapsed menu.
    */
    isOpenId: string;
  };
  route: {
    current: string;
    prev: string;
  };
}

const initialState: UiInterface = {
  settings: {
    version: 2,
    isLoaded: false,
    site: {
      title: 'Otto',
      faviconUrl: null,
      logoUrl: null,
      logoUrlContrast: null,
      theme: {},
      disableCoachingIntro: false,
      naming: {
        service: 'advise',
        persona: 'adviser'
      }
    },
    coachTitle: 'Adviser'
  },
  hasLoadedSettings: false,
  mobileMenuOpen: false,
  dashboardView: 'homeView',
  theme: UiThemeEnum.LIGHT,
  prompts: {
    connectAccounts: true,
  },
  wealthTab: WealthTabsEnum.OVERVIEW,
  features: 2,
  isDialogOpen: false,
  pageName: null,
  pages: {
    dashboard: {
      isFull: false,
      tour: {
        version: 0,
        currentVersion: 1,
      }
    },
    investments: {
      isFull: false,
      tour: {
        version: 0,
        currentVersion: 1,
      }
    },
    investmentsAnalysis: {
      isFull: false,
      tour: {
        version: 0,
        currentVersion: 1,
      }
    }
  },
  sidebar: {
    isWide: true,
    isOpenId: null
  },
  route: {
    current: null,
    prev: null
  }
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebar: (state, { payload }: PayloadAction<boolean>) => {
      state.sidebar.isWide = payload;
    },
    toggleSidebarCollapse: (state, { payload }: PayloadAction<{ id: string; }>) => {
      state.sidebar.isOpenId = state.sidebar.isOpenId === payload.id ? null : payload.id;
    },
    setIsDialogOpen: (state, action) => {
      state.isDialogOpen = action.payload;
    },
    setDashboardView: (state, action) => {
      state.dashboardView = action.payload;
    },
    switchTheme: (state, action) => {
      state.theme = action.payload;
    },
    setPrompts: (state, action) => {
      state.prompts = {
        ...state.prompts,
        ...action.payload,
      };
    },
    setWealthTab: (state, action) => {
      state.wealthTab = action.payload;
    },
    setPageName: (state, action) => {
      state.pageName = action.payload;
    },
    setPageTour: (state, { payload }: PayloadAction<{ name: string; version: number; }>) => {
      state.pages[payload.name].tour.version = payload.version;
    },
    setPageFull: (state, { payload }: PayloadAction<string>) => {
      state.pages[payload].isFull = true;
    },
    updateUIRouteAction: (state, { payload }: PayloadAction<string>) => {
      if (payload !== state.route.current) {
        state.route = {
          current: payload,
          prev: state.route.current
        };
      }
    },
    updateThemeSettings: (state, { payload }: PayloadAction<SiteSettings | null>) => {
      state.settings = {
        ...state.settings,
        site: {
          ...state.settings.site,
          ...(payload?.title && {
            ...payload,
            naming: {
              ...payload.naming,
              service: payload.naming?.service || state.settings.site.naming.service,
              persona: payload.naming?.persona || state.settings.site.naming.persona
            }
          })
        },
        isLoaded: true,
        version: SETTINGS_VER,
        ...(payload?.naming && {
          coachTitle: capitalizeFirstLetter(payload.naming.persona)
        })
      };
      saveThemeOverride(overrideThemeUtil(payload.theme ?? {}));
      saveState({ ui: state });
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, (state) => {
        state.route = {
          current: '',
          prev: ''
        };
      })
      .addMatcher(settingsApi.endpoints.settings.matchPending, (state) => {
        state.hasLoadedSettings = false;
      })
      .addMatcher(settingsApi.endpoints.settings.matchFulfilled, (state, { payload }) => {
        Object.keys(payload.pages).forEach(p => {
          state.pages[p].tour.version = payload.pages[p].tour?.version ?? 1;
        });
        state.hasLoadedSettings = true;
      });
  },
});

export default uiSlice.reducer;
export const {
  setDashboardView,
  switchTheme,
  setPrompts,
  setIsDialogOpen,
  setPageName,
  setWealthTab,
  setPageFull,
  updateThemeSettings,
  setPageTour,
  toggleSidebarCollapse,
  toggleSidebar,
  updateUIRouteAction
} = uiSlice.actions;

const selectUi = (state: StoreInterface) => state.ui;

export const uiSelector = createSelector((state: StoreInterface) => state, values => values.ui);
export const uiPromptsSelector = createSelector(selectUi, v => v.prompts);
export const uiSettingsSelector = createSelector(selectUi, v => v.settings);
export const uiSidebarSelector = createSelector(uiSelector, v => v.sidebar);
export const uiRouteSelector = createSelector(uiSelector, v => v.route);
